// src/components/Email/EmailDashboard.js

import React, { useState } from 'react';
import { Box, CssBaseline, AppBar, Toolbar, Typography, Fab } from '@mui/material';
import { Chat } from '@mui/icons-material';
import EmailListView from './EmailListView';
import ChatComponent from '../ChatComponent';

const EmailDashboard = () => {
  const [showChat, setShowChat] = useState(false);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ width: '100%' }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Email Management System
          </Typography>
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
        <Toolbar />
        <EmailListView />
        {showChat && <ChatComponent />}
        <Fab
          color="primary"
          aria-label="chat"
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
          onClick={() => setShowChat(!showChat)}
        >
          <Chat />
        </Fab>
      </Box>
    </Box>
  );
};

export default EmailDashboard;
