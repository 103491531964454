// src/components/Email/EmailListView.js
import React, { useEffect, useState } from 'react';
import { getEmails } from '../../services/api';
import EmailItem from './EmailItem';
import './EmailListView.css';

const EmailList = () => {
    const [emails, setEmails] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const userId = localStorage.getItem('userId'); // Assuming userId is stored in localStorage

    useEffect(() => {
        const fetchEmails = async () => {
            try {
                const emails = await getEmails(userId);
                setEmails(emails);
            } catch (error) {
                console.error('Failed to fetch emails:', error);
            }
        };

        fetchEmails();
    }, [userId]);

    return (
        <div className="email-dashboard">
            <div className="sidebar">
                <button className="new-email-button">New Email</button>
                <nav className="folders">
                    <ul>
                        <li>Inbox</li>
                        <li>Archive</li>
                        <li>Drafts</li>
                        <li>Sent</li>
                        <li>Deleted Items</li>
                        <li>Junk Email</li>
                    </ul>
                </nav>
            </div>
            <div className="email-list">
                <h2>Inbox</h2>
                {emails.length > 0 ? (
                    emails.map((email) => (
                        <EmailItem key={email.EmailID} email={email} onSelect={() => setSelectedEmail(email)} />
                    ))
                ) : (
                    <p>No emails found.</p>
                )}
            </div>
            <div className="email-content">
                {selectedEmail ? (
                    <div>
                        <h3>{selectedEmail.Subject}</h3>
                        <p>{selectedEmail.Body}</p>
                        <small>{new Date(selectedEmail.TimeStamp).toLocaleString()}</small>
                    </div>
                ) : (
                    <div className="placeholder">
                        <img src="placeholder-icon.png" alt="No Conversation Selected" />
                        <p>No Conversation Selected</p>
                        <p>Select a conversation to read.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmailList;
