import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, TextField, Button, Typography, Paper } from '@mui/material';
import { login } from '../../services/api';
import './LoginPage.css';

const LoginPage = () => {
    const [form, setForm] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const validate = () => {
        let tempErrors = {};
        tempErrors.email = (/\S+@\S+\.\S+/).test(form.email) ? "" : "Email is invalid.";
        tempErrors.password = form.password ? "" : "Password is required.";
        setErrors({ ...tempErrors });
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                const response = await login(form.email, form.password);
                if (response.token) {
                    localStorage.setItem('token', response.token);
                    navigate('/emails');
                } else {
                    alert(response.message || 'Invalid credentials');
                }
            } catch (error) {
                console.error('Login failed:', error);
                alert('Invalid credentials');
            }
        }
    };

    return (
        <div className="login-page">
            <Container component="main" maxWidth="md" className="login-form-container">
                <Paper elevation={3} sx={{ padding: 4, width: '100%', maxWidth: 480 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src="https://static.vecteezy.com/system/resources/thumbnails/006/827/459/small/email-icon-sign-symbol-logo-vector.jpg" alt="Mail" className="logo" />
                        <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                            Sign in with Email ID
                        </Typography>
                        <Box component="form" sx={{ mt: 1, width: '100%' }} onSubmit={handleLogin}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                value={form.email}
                                onChange={handleChange}
                                {...(errors.email && { error: true, helperText: errors.email })}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                required
                                fullWidth
                                id="password"
                                label="Password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                value={form.password}
                                onChange={handleChange}
                                {...(errors.password && { error: true, helperText: errors.password })}
                                sx={{ mb: 2 }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </div>
    );
};

export default LoginPage;
