import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, TextField, Button, Typography, Grid, Paper } from '@mui/material';
import './SignupPage.css'; // Import the CSS file

const SignupPage = () => {
  const [form, setForm] = useState({ firstName: '', lastName: '', dob: '', email: '', phone: '' });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.firstName = form.firstName ? "" : "First name is required.";
    tempErrors.dob = form.dob ? "" : "Date of birth is required.";
    tempErrors.email = form.email ? "" : "Email is required.";
    tempErrors.phone = form.phone ? "" : "Phone number is required.";
    setErrors({ ...tempErrors });
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      navigate('/signup-details', { state: form });
    }
  };

  return (
    <div className="signup-page">
      <Container component="main" maxWidth="md" className="signup-form-container">
        <Paper elevation={3} sx={{ padding: 4, width: '100%', maxWidth: 480 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
              Create an Email Account
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 3 }}>
              Enter your details
            </Typography>
            <Box component="form" sx={{ mt: 1, width: '100%' }} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="firstName"
                    label="First name"
                    name="firstName"
                    autoComplete="given-name"
                    autoFocus
                    value={form.firstName}
                    onChange={handleChange}
                    {...(errors.firstName && { error: true, helperText: errors.firstName })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="lastName"
                    label="Last name (optional)"
                    name="lastName"
                    autoComplete="family-name"
                    value={form.lastName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="dob"
                    label="Date of Birth"
                    name="dob"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={form.dob}
                    onChange={handleChange}
                    {...(errors.dob && { error: true, helperText: errors.dob })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    value={form.email}
                    onChange={handleChange}
                    {...(errors.email && { error: true, helperText: errors.email })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="phone"
                    label="Phone Number"
                    name="phone"
                    autoComplete="tel"
                    value={form.phone}
                    onChange={handleChange}
                    {...(errors.phone && { error: true, helperText: errors.phone })}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default SignupPage;
