import React from 'react';
import { Container, Box, Typography, Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './SignupSuccessPage.css'; // Ensure to import the CSS file for custom styles

const SignupSuccessPage = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <Container component="main" maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <Paper elevation={3} sx={{ padding: 4, width: '100%', maxWidth: 480, textAlign: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
            Account Created Successfully!
          </Typography>
          <Box sx={{ mt: 3, mb: 2 }}>
            <img src="https://i.gifer.com/7efs.gif" alt="Success" className="success-animation" />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
            sx={{ mt: 3 }}
          >
            Go to Login
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default SignupSuccessPage;
