// src/components/Settings/SecuritySettings.js
import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { getSecuritySettings, updateSecuritySettings } from '../../services/api';

const SecuritySettings = () => {
  const [settings, setSettings] = useState({
    password: '',
    // Add other security settings here
  });

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    const userId = 1; // Replace with actual user ID
    const securitySettings = await getSecuritySettings(userId);
    setSettings(securitySettings);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings({ ...settings, [name]: value });
  };

  const handleSubmit = async () => {
    const userId = 1; // Replace with actual user ID
    await updateSecuritySettings(userId, settings);
    // Notify user of success
  };

  return (
    <Box>
      <Typography variant="h6">Security Settings</Typography>
      <TextField
        label="Password"
        name="password"
        type="password"
        value={settings.password}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" onClick={handleSubmit}>
        Save
      </Button>
    </Box>
  );
};

export default SecuritySettings;
