// src/components/Auth/SignupPasswordPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { register } from '../../services/api'; // Import the register function
import { Container, Box, TextField, Button, Typography, Paper } from '@mui/material';

const SignupPasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') setPassword(value);
    if (name === 'confirmPassword') setConfirmPassword(value);
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.password = password ? "" : "Password is required.";
    tempErrors.confirmPassword = confirmPassword ? "" : "Confirm password is required.";
    if (password && confirmPassword && password !== confirmPassword) {
      tempErrors.confirmPassword = "Passwords do not match.";
    }
    setErrors({ ...tempErrors });
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const userData = JSON.parse(localStorage.getItem('signupData'));
        userData.password = password;
        const response = await register(userData);
        if (response.success) {
          navigate('/signup-success');
        } else {
          setErrors({ ...errors, form: response.message });
        }
      } catch (error) {
        console.error('Registration failed:', error);
        setErrors({ ...errors, form: 'Registration failed. Please try again.' });
      }
    }
  };

  return (
    <Container component="main" maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <Paper elevation={3} sx={{ padding: 4, width: '100%', maxWidth: 480 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
            Create Password
          </Typography>
          <Box component="form" sx={{ mt: 1, width: '100%' }} onSubmit={handleSubmit}>
            <TextField
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={handleChange}
              {...(errors.password && { error: true, helperText: errors.password })}
              margin="normal"
            />
            <TextField
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={handleChange}
              {...(errors.confirmPassword && { error: true, helperText: errors.confirmPassword })}
              margin="normal"
            />
            {errors.form && (
              <Typography color="error" variant="body2" align="center" sx={{ mt: 2 }}>
                {errors.form}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Create Account
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default SignupPasswordPage;
