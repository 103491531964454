import React from 'react';
import { ListItem, ListItemText, Typography } from '@mui/material';
import './EmailItem.css';

const EmailItem = ({ email, onSelect }) => {
  return (
    <ListItem button onClick={onSelect}>
      <ListItemText primary={email.Subject} secondary={email.Body} />
      <Typography variant="caption">{new Date(email.TimeStamp).toLocaleString()}</Typography>
    </ListItem>
  );
};

export default EmailItem;
