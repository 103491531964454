// src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

// Mock user database for testing purposes
const mockUsers = [
  { email: 'test@example.com', password: 'password' },
  { email: 'user1@example.com', password: 'user1password' },
  { email: 'user2@example.com', password: 'user2password' },
];

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  const login = async (email, password) => {
    console.log("Attempting to login with email:", email, "and password:", password);
    // Validate credentials against the mock user database
    const user = mockUsers.find((user) => user.email === email && user.password === password);
    if (user) {
      console.log("User found:", user);
      setCurrentUser({ email });
      localStorage.setItem('token', 'dummy-token');
      navigate('/emails');
    } else {
      console.log("User not found or invalid credentials.");
      throw new Error('Invalid credentials');
    }
  };

  const logout = () => {
    setCurrentUser(null);
    localStorage.removeItem('token');
    navigate('/login');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setCurrentUser({ email: 'test@example.com' }); // Mock user retrieval
    }
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
