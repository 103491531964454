import React from 'react';
import EmailUsageChart from './EmailUsageChart';
import UserStatsChart from './UserStatsChart';
import './AdminDashboard.css';

const AdminDashboard = () => {
    const emailUsageData = [
        {
            label: 'Sent Emails',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: 'rgba(75,192,192,0.4)',
        },
        {
            label: 'Received Emails',
            data: [2, 3, 20, 5, 1, 4],
            backgroundColor: 'rgba(153,102,255,0.4)',
        },
    ];

    const userStatsData = [
        {
            label: 'Active Users',
            data: [65, 59, 80, 81, 56, 55, 40],
            borderColor: 'rgba(75,192,192,1)',
            backgroundColor: 'rgba(75,192,192,0.2)',
        },
    ];

    return (
        <div className="admin-dashboard">
            <h2>Email Usage</h2>
            <EmailUsageChart datasets={emailUsageData} />
            <h2>User Statistics</h2>
            <UserStatsChart datasets={userStatsData} />
        </div>
    );
};

export default AdminDashboard;
