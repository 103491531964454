// src/components/Auth/Navbar.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const navigate = useNavigate();

  const handleSignInClick = () => {
    navigate('/login');
  };

  const handleCreateAccountClick = () => {
    navigate('/signup');
  };

  const handleMyBlogClick = () => {
    window.location.href = 'http://cka0054.uta.cloud'; // Replace with your blog URL
  };

  return (
    <div className="navbar">
      <div className="navbar-left">
        <span className="brand-name">Email Management System</span>
      </div>
      <div className="navbar-right">
        <button className="navbar-button" onClick={handleSignInClick}>Sign in</button>
        <button className="navbar-button" onClick={handleCreateAccountClick}>Create Account</button>
        <button className="navbar-button" onClick={handleMyBlogClick}>My Blog</button>
      </div>
    </div>
  );
};

export default Navbar;
