import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import Footer from './Footer'; 

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="home-page">
      <div className="content">
        <div className="header-container">
          <div className="header">Email Management System.</div>
          <div className="sub-header">Organize your emails with AI assistance.</div>
        </div>
        <button className="sign-in-button" onClick={() => navigate('/login')}>Sign In</button>
        <p className="description">The best place for all your Mail, notes and more.</p>
        <div className="cards-container">
          <div className="card">
            <div className="card-icons">
              <img src="https://media.istockphoto.com/id/1125279178/vector/mail-line-icon.jpg?s=612x612&w=0&k=20&c=NASq4hMg0b6UP9V0ru4kxL2-J114O3TaakI467Pzjzw=" alt="Email" />
              <img src="https://www.shutterstock.com/image-vector/www-search-bar-icon-isolated-260nw-1935544219.jpg" alt="Calendar" />
            </div>
            <div className="card-content">
              <h2>Smart Inbox with AI-Powered Search</h2>
              <p>Prioritize important emails automatically with our intelligent sorting system, ensuring you never miss a critical message.
                Find emails instantly with our advanced AI search functionality that understands natural language queries.</p>
            </div>
          </div>
          <div className="card">
            <div className="card-icons">
              <img src="https://www.creativefabrica.com/wp-content/uploads/2021/06/12/User-privacy-icon-Graphics-13308035-1-1-580x386.jpg" alt="Privacy" />
              <img src="https://public-images.interaction-design.org/literature/articles/heros/article_130515_hero_636e39613117f1.68703759.jpg" alt="Responsive Design" />
            </div>
            <div className="card-content">
              <h2>Enhanced Security Responsive Design</h2>
              <p>Benefit from state-of-the-art AI-driven security measures to protect your emails from phishing and spam.
                Access your emails seamlessly across all devices with a responsive design that adapts to any screen size.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer /> {/* Add the Footer component here */}
    </div>
  );
}

export default HomePage;
