// src/components/Settings/AppearanceSettings.js
import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, MenuItem } from '@mui/material';
import { getAppearanceSettings, updateAppearanceSettings } from '../../services/api';

const themes = [
  { value: 'light', label: 'Light' },
  { value: 'dark', label: 'Dark' },
  // Add other themes here
];

const AppearanceSettings = () => {
  const [settings, setSettings] = useState({
    theme: 'light',
    // Add other appearance settings here
  });

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    const userId = 1; // Replace with actual user ID
    const appearanceSettings = await getAppearanceSettings(userId);
    setSettings(appearanceSettings);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings({ ...settings, [name]: value });
  };

  const handleSubmit = async () => {
    const userId = 1; // Replace with actual user ID
    await updateAppearanceSettings(userId, settings);
    // Notify user of success
  };

  return (
    <Box>
      <Typography variant="h6">Appearance Settings</Typography>
      <TextField
        label="Theme"
        name="theme"
        select
        value={settings.theme}
        onChange={handleChange}
        fullWidth
        margin="normal"
      >
        {themes.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <Button variant="contained" onClick={handleSubmit}>
        Save
      </Button>
    </Box>
  );
};

export default AppearanceSettings;
