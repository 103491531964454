import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';
import './EmailUsageChart.css';

const EmailUsageChart = ({ datasets }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        if (!datasets || datasets.length === 0) return;

        const ctx = chartRef.current.getContext('2d');
        const chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: datasets.map(ds => ds.label),
                datasets: datasets.map(ds => ({
                    label: ds.label,
                    data: ds.data,
                    backgroundColor: ds.backgroundColor,
                })),
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
        });

        return () => {
            chart.destroy();
        };
    }, [datasets]);

    return (
        <div className="email-usage-chart">
            <canvas ref={chartRef}></canvas>
        </div>
    );
};

export default EmailUsageChart;
