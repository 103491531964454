// src/components/Settings/AccountSettings.js
import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { getAccountSettings, updateAccountSettings } from '../../services/api';

const AccountSettings = () => {
  const [settings, setSettings] = useState({
    email: '',
    username: '',
    // Add other account settings here
  });

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    const userId = 1; // Replace with actual user ID
    const accountSettings = await getAccountSettings(userId);
    setSettings(accountSettings);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings({ ...settings, [name]: value });
  };

  const handleSubmit = async () => {
    const userId = 1; // Replace with actual user ID
    await updateAccountSettings(userId, settings);
    // Notify user of success
  };

  return (
    <Box>
      <Typography variant="h6">Account Settings</Typography>
      <TextField
        label="Email"
        name="email"
        value={settings.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Username"
        name="username"
        value={settings.username}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" onClick={handleSubmit}>
        Save
      </Button>
    </Box>
  );
};

export default AccountSettings;
