const API_URL = 'http://localhost:5000/api';

// Register a new user
export const register = async (userData) => {
    const response = await fetch(`${API_URL}/user/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
    });
    return response.json();
};

// Log in a user
export const login = async (email, password) => {
    const response = await fetch(`${API_URL}/user/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
    });
    return response.json();
};

// Get all emails for a user
export const getEmails = async (userId) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/email/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const data = await response.json();
    return Array.isArray(data) ? data : [];
};

// Create a new email
export const createEmail = async (emailData) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/email`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
    });
    return response.json();
};

// Send an email
export const sendEmail = async (emailData) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/email/send`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
    });
    return response.json();
};

// Update an existing email
export const updateEmail = async (emailId, emailData) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/email/${emailId}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
    });
    return response.json();
};

// Delete an email
export const deleteEmail = async (emailId) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/email/${emailId}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.json();
};

// Get account settings for a user
export const getAccountSettings = async (userId) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/settings/account/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.json();
};

// Update account settings for a user
export const updateAccountSettings = async (userId, settingsData) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/settings/account/${userId}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(settingsData),
    });
    return response.json();
};

// Get appearance settings for a user
export const getAppearanceSettings = async (userId) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/settings/appearance/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.json();
};

// Update appearance settings for a user
export const updateAppearanceSettings = async (userId, settingsData) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/settings/appearance/${userId}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(settingsData),
    });
    return response.json();
};

// Get security settings for a user
export const getSecuritySettings = async (userId) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/settings/security/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.json();
};

// Update security settings for a user
export const updateSecuritySettings = async (userId, settingsData) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/settings/security/${userId}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(settingsData),
    });
    return response.json();
};

// Get notification settings for a user
export const getNotificationSettings = async (userId) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/settings/notifications/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.json();
};

// Update notification settings for a user
export const updateNotificationSettings = async (userId, settingsData) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/settings/notifications/${userId}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(settingsData),
    });
    return response.json();
};
