import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import HomePage from './components/Auth/HomePage';
import LoginPage from './components/Auth/LoginPage';
import SignupPage from './components/Auth/SignupPage';
import EmailDashboard from './components/Email/EmailDashboard';
import PrivateRoute from './components/PrivateRoute';
import SignupPasswordPage from './components/Auth/SignupPasswordPage';
import SignupSuccessPage from './components/Auth/SignupSuccessPage';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import MainNavbar from './components/Navbar/MainNavbar'; // Ensure MainNavbar is imported correctly
import AccountSettings from './components/Settings/AccountSettings';
import AppearanceSettings from './components/Settings/AppearanceSettings';
import SecuritySettings from './components/Settings/SecuritySettings';

const AppContent = ({ openCompose, setOpenCompose }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
      <CssBaseline />
      <MainNavbar setOpenCompose={setOpenCompose} />
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/signup-details" element={<SignupPasswordPage />} />
        <Route path="/signup-success" element={<SignupSuccessPage />} />
        <Route path="/emails" element={<PrivateRoute />}>
          <Route index element={<EmailDashboard />} />
        </Route>
        <Route path="/admin" element={<PrivateRoute />}>
          <Route index element={<AdminDashboard />} />
        </Route>
        <Route path="/account-settings" element={<PrivateRoute />}>
          <Route index element={<AccountSettings />} />
        </Route>
        <Route path="/appearance-settings" element={<PrivateRoute />}>
          <Route index element={<AppearanceSettings />} />
        </Route>
        <Route path="/security-settings" element={<PrivateRoute />}>
          <Route index element={<SecuritySettings />} />
        </Route>
      </Routes>
    </Box>
  );
};

function App() {
  const [openCompose, setOpenCompose] = React.useState(false);

  return <AppContent openCompose={openCompose} setOpenCompose={setOpenCompose} />;
}

export default App;
