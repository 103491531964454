// src/components/ChatComponent.js

import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { Box, TextField, Button, List, ListItem, ListItemText, Typography, IconButton, Paper } from '@mui/material';
import { Close } from '@mui/icons-material';

const socket = io('http://localhost:5000');

const ChatComponent = ({ onClose }) => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    socket.on('receiveMessage', (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      socket.off('receiveMessage');
    };
  }, []);

  const sendMessage = () => {
    if (message) {
      socket.emit('sendMessage', message);
      setMessage('');
    }
  };

  return (
    <Paper sx={{ position: 'fixed', bottom: 80, right: 16, width: 300, boxShadow: 3, borderRadius: 2, p: 2, zIndex: 1000 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" gutterBottom>Chat</Typography>
        <IconButton size="small" onClick={onClose}><Close /></IconButton>
      </Box>
      <List sx={{ maxHeight: 200, overflow: 'auto' }}>
        {messages.map((msg, index) => (
          <ListItem key={index}>
            <ListItemText primary={msg} />
          </ListItem>
        ))}
      </List>
      <TextField
        label="Type a message"
        variant="outlined"
        fullWidth
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') sendMessage();
        }}
        sx={{ mt: 1 }}
      />
      <Button variant="contained" color="primary" fullWidth onClick={sendMessage} sx={{ mt: 1 }}>
        Send
      </Button>
    </Paper>
  );
};

export default ChatComponent;
